import React, { useEffect, useState } from "react"

import {
  Elements,
  PaymentMethodMessagingElement,
} from "@stripe/react-stripe-js"
import { Stripe } from "@stripe/stripe-js"
import getStripe from "../../utils/stripejs"
import { Currency } from "../types/checkoutTypes"

const AffirmMessagingUSD = ({ total }: { total: number }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    getStripe("US").then(setStripe)
  }, [])

  return stripe ? (
    <Elements stripe={stripe}>
      <PaymentMethodMessagingElement
        options={{
          amount: total,
          currency: "USD",
          countryCode: "US",
          paymentMethodTypes: ["affirm"],
        }}
      />
    </Elements>
  ) : null
}

const AffirmMessagingCAD = ({ total }: { total: number }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    getStripe("CA").then(setStripe)
  }, [])

  return stripe ? (
    <Elements stripe={stripe}>
      <PaymentMethodMessagingElement
        options={{
          amount: total,
          currency: "CAD",
          countryCode: "CA",
          paymentMethodTypes: ["affirm"],
        }}
      />
    </Elements>
  ) : null
}

const AffirmMessaging = ({
  total,
  currency,
  country,
  enableAffirmCanada,
  enableAffirmUS,
}: {
  total: number
  currency: Currency
  country: string
  enableAffirmCanada: boolean
  enableAffirmUS: boolean
}) => {
  return country === "USA" && enableAffirmUS ? (
    <AffirmMessagingUSD total={total} />
  ) : country === "CAN" && enableAffirmCanada ? (
    <AffirmMessagingCAD total={total} />
  ) : null
}

export default AffirmMessaging
