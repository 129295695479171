import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import { Dividor } from "./line"

const Title: React.FC = ({ children }) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {children}
    </Typography>
  )
}

export default Title

const SectionHeading = styled.h2<{ titleColor?: string }>`
  margin-bottom: 1rem;
  color: ${({ titleColor }) =>
    titleColor === "blue"
      ? "var(--color-primary)"
      : titleColor === "pink"
      ? "var(--color-hot-pink)"
      : titleColor?.includes("gradient")
      ? "transparent"
      : "initial"};
  background: ${({ titleColor }) =>
    titleColor === "gradient"
      ? "var(--color-gradient)"
      : titleColor === "gradientRed"
      ? "var(--color-gradient-rot)"
      : titleColor === "gradientTeal"
      ? "var(--color-gradient-teal)"
      : titleColor === "gradientPurple"
      ? "var(--color-gradient-purple)"
      : titleColor === "gradientGrey"
      ? "var(--color-gradient-grey)"
      : "initial"};
  background-clip: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "text" : "initial"};
  -webkit-background-clip: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "text" : "initial"};
  text-fill-color: ${({ titleColor }) =>
    titleColor?.includes("gradient") ? "transparent" : "initial"};
`

const SubHeading = styled.h3<{ subtitleColor?: string }>`
  color: ${({ subtitleColor }) =>
    subtitleColor === "blue"
      ? "var(--color-primary)"
      : subtitleColor === "pink"
      ? "var(--color-hot-pink)"
      : subtitleColor?.includes("gradient")
      ? "transparent"
      : "initial"};
  background: ${({ subtitleColor }) =>
    subtitleColor === "gradient"
      ? "var(--color-gradient)"
      : subtitleColor === "gradientRed"
      ? "var(--color-gradient-rot)"
      : subtitleColor === "gradientTeal"
      ? "var(--color-gradient-teal)"
      : subtitleColor === "gradientPurple"
      ? "var(--color-gradient-purple)"
      : subtitleColor === "gradientGrey"
      ? "var(--color-gradient-grey)"
      : "initial"};
  background-clip: ${({ subtitleColor }) =>
    subtitleColor?.includes("gradient") ? "text" : "initial"};
  -webkit-background-clip: ${({ subtitleColor }) =>
    subtitleColor?.includes("gradient") ? "text" : "initial"};
  text-fill-color: ${({ subtitleColor }) =>
    subtitleColor?.includes("gradient") ? "transparent" : "initial"};
`

export const SectionTitle = ({
  title,
  titleColor,
  subtitle,
  subtitleColor,
  animate,
  titleAlign,
  underlineTitle,
  smallSubTitle,
  gradient,
  color,
}: {
  title: string | undefined
  titleColor?: string | undefined
  subtitle?: string | undefined
  subtitleColor?: string | undefined
  animate?: boolean | undefined
  titleAlign?: "left" | "center" | "right"
  underlineTitle?: boolean
  smallSubTitle?: boolean
  gradient?: boolean
  color?: string
}) => {
  return (
    <div style={{ textAlign: titleAlign ? titleAlign : "center" }}>
      {title && (
        <SectionHeading
          className={gradient ? "gradientText" : ""}
          titleColor={titleColor}
          dangerouslySetInnerHTML={{ __html: title }}
        ></SectionHeading>
      )}
      {subtitle && (
        <SubHeading
          data-aos={animate && "fade-left"}
          subtitleColor={subtitleColor}
          className={smallSubTitle ? "smallSub" : ""}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></SubHeading>
      )}
      {underlineTitle && (
        <Dividor
          height="2px"
          color={color || "var(--color-black)"}
          margin="-0.5rem 0 0rem 0"
        />
      )}
    </div>
  )
}
