import React from "react"
import styled from "styled-components"

interface TagProps {
  text: string
  color?: string
  textColor?: string
}

const TagS = styled.div<TagProps>`
  display: inline-block;
  background-color: ${({ color }) => (color ? color : "var(--color-grey-3)")};
  color: ${({ textColor }) => (textColor ? textColor : "var(--color-white)")};
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  border-radius: 1rem 0.5rem 0.5rem 1rem;
  padding: 0 1rem;
  white-space: nowrap;
`

export const Tag = (props: TagProps) => {
  return <TagS {...props}>{props.text}</TagS>
}
