import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
.frontrow {
  margin:  1rem 0;
}
.frontrow div:empty {
  display: flex;
}

.frontrow span:empty {
  display: flex;
}

.fr-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.fr-text span.fr-shorter {
  width: 80%;
}

.fr-text span {
  display: flex;
  width: 100%;
  background-color: #E0E0E0;
  height: 20px;
  border-radius: 100px;
}

.fr-loading-box {
  display: flex;
  padding: 18px 16px;
  gap: 14px;
  border-radius: 12px;
  background-color: #FFF;
  border: none;
  width: 100%;
  cursor: pointer;
}

.fr-loading-provider {
  border-radius: 100px;
  width: 36px;
  height: 36px;
  border: 2px #FFF solid;
  background-color: #E0E0E0;
}

.fr-provider-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 80px;
}
.frontrow button{
  border: none !important;
}
.frontrow .fr-quant-container{
  background-color: #F7F8F9 !important;
  padding: 12px !important;
}

.fr-sample {
  margin-left: -14px;
}

@media only screen and (max-width: 480px) {
  .fr-loading-box {
    padding: 14px 12px;
    gap: 8px;
  }
`
const makeid = (length: number): string => {
  let result = ""
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const FrontrowWidget = () => {
  const [hide, setHide] = useState(false)
  const id = makeid(10)
  const widgetId = "frontrow-widget=" + id

  useEffect(() => {
    window.openFrontrowModal = () => {
      const modal = document.getElementById("frontrow-modal")
      if (modal instanceof HTMLElement) {
        document.body.appendChild(modal)
        modal.style.display = "block"
      }
    }

    window.closeFrontrowModal = () => {
      const modal = document.getElementById("frontrow-modal")
      if (modal instanceof HTMLElement) {
        modal.style.display = "none"
      }
    }

    var url =
      "https://app.thefrontrowhealth.com/api/widgets?presentation_type=quant&product_id=980"

    const fetchDataAndUpdateDiv = () => {
      if (url) {
        fetch(url)
          .then(response => {
            if (response.ok) return response.text()
            throw new Error("failed to fetch content")
          })
          .then(html => {
            const widget = document.getElementById(widgetId)
            if (widget) widget.innerHTML = html
            setHide(false)
          })
          .catch(() => {
            const widget = document.getElementById(widgetId)
            if (widget) widget.innerHTML = ""
            setHide(true)
          })
      } else {
        const widget = document.getElementById(widgetId)
        if (widget) widget.innerHTML = ""
        setHide(true)
      }
    }

    fetchDataAndUpdateDiv()
  }, [])

  return (
    <Wrapper>
      <div id={widgetId} className="frontrow">
        {hide && (
          <div className="fr-loading-box">
            <div className="fr-provider-container">
              <div className="fr-loading-provider"></div>
              <div className="fr-loading-provider fr-sample"></div>
              <div className="fr-loading-provider fr-sample"></div>
            </div>
            <div className="fr-text">
              <span></span>

              <span className="fr-shorter"></span>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default FrontrowWidget
