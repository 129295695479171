import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { formatCurrencyString } from "../../utils/format"
import {
  ProductPriceObj,
  ProductDisplay,
  InventoryBreakpoints,
  CartProduct,
} from "../../ts/interfaces"
import styled from "styled-components"
import { devices } from "../config/breakpoints"
import { getLs, setLs } from "../../utils/localStorage"
import { Tag } from "../ui/Tag"
import { getCartProduct, getStripeId } from "../../utils/stripejs"
import { useLazyApi } from "../../hooks/useLazyApi"
import { useEffect } from "react"
import { useCheckoutState } from "../../checkout/state"
import { Currency } from "../../checkout/types/checkoutTypes"
import { isHeadsetSubscriptionPromoApplicable } from "../../checkout/shared/calcStateItems"
import AffirmMessaging from "../../checkout/components/AffirmMessaging"
import FrontrowWidget from "./FrontRow"

type StrapiData = {
  strapi: {
    siteConfig: {
      enableAffirmMessaging: boolean
      enableAffirmCanada: boolean
      enableAffirmUS: boolean
      enableFrontRow: boolean
    }
  }
}

// TODO: Remove this margin as this component is used in several places and it's not great for the checkout component
// - will need to update other sections in the store to compensate
export const Wrapper = styled.div`
  display: flex;
  vertical-align: center;
  justify-content: flex-end;
  margin: 1rem 0;
  @media ${devices.mobilePhone} {
    margin: 0;
  }
`

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PriceDisplayMobileContainer = styled.div<{ hasMargin: boolean }>`
  margin-top: ${({ hasMargin }) => (hasMargin ? "1rem" : "0")};
`

export const Card = styled.div<{ small: boolean }>`
  padding: ${({ small }) => (small ? "0.6rem 0" : "1rem 0")};
  padding-top: 0;
  background-color: var(--color-white);
  display: flex;
  vertical-align: center;
  text-align: left;
  justify-content: start;
`

export const Row = styled.div`
  display: flex;
  vertical-align: center;
  text-align: left;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
`

const Title = styled.div<{ small: boolean }>`
  font-family: var(--font-family-header);
  letter-spacing: 0.5px;
  font-weight: 700;
  color: var(--color-black);
  line-height: 1.5em;
  margin-right: 1rem;
  text-align: left;
  font-size: ${({ small }) => (small ? "1rem" : "1.25rem")};
  @media ${devices.mobile} {
    line-height: 1.2rem;
    font-size: 1.1rem;
  }
`

const Dividor = styled.div<{ small: boolean }>`
  width: 1px;
  height: 50%;
  margin: ${({ small }) => (small ? "0 1rem" : "0 2.5rem")};
  @media ${devices.mobile} {
    margin: 0 1em;
  }
`

const StyledPrice = styled.div<{ small: boolean; color?: string }>`
  font-family: var(--font-family-header);
  font-weight: 200;
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 3rem;

  color: ${({ color }) => color || "var(--color-teal)"};
  font-size: ${({ small }) => (small ? "2.4rem" : "2.8rem")};
  margin: 0;
  sup {
    text-transform: uppercase;
    vertical-align: super;
    font-size: ${({ small }) => (small ? "1.3rem" : "1.6rem")};
    color: var(--color-black);
    font-weight: 400;
    margin-left: 0.4rem;
  }
  @media ${devices.mobile} {
    margin-bottom: 1rem;
    line-height: 2rem;
    font-size: ${({ small }) => (small ? "1.875rem" : "2rem")};
    sup {
      font-size: ${({ small }) => (small ? "1rem" : "1.1rem")};
    }
  }
`
const StyledPriceMobile = styled.div<{ color?: string }>`
  font-family: var(--font-family-header);
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 1px;
  color: var(--color-purple);
  sup {
    text-transform: uppercase;
    vertical-align: super;
    font-size: 0.8rem;
    margin-left: 0.2rem;
  }
`

const PriceStrike = styled.div`
  font-family: var(--font-family-header);
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.1em;
  margin-left: 2rem;
  margin-top: 0.5rem;
  max-width: 45%;
  .strike {
    color: var(--color-grey-3);
    text-decoration: line-through;
    margin-right: 1rem;
  }
  span {
    color: var(--color-ultra-violet);
    text-decoration: none;
  }
  @media ${devices.mobile} {
    margin-left: 0;
  }
`

const BillingPeriod = styled.div`
  font-weight: 400;
  color: var(--color-grey-3);
  font-size: 1.1rem;
  line-height: 1.2;
`

const InventoryMessage = styled.div<{ inventoryColor: string }>`
  color: ${({ inventoryColor }) =>
    inventoryColor === "blue"
      ? "var(--color-primary)"
      : inventoryColor === "pink"
      ? "var(--color-hot-pink)"
      : inventoryColor?.includes("gradient")
      ? "transparent"
      : "initial"};
  font-weight: 400;
  background: ${({ inventoryColor }) =>
    inventoryColor === "gradient"
      ? "var(--color-gradient)"
      : inventoryColor === "gradientRed"
      ? "var(--color-gradient-rot)"
      : inventoryColor === "gradientTeal"
      ? "var(--color-gradient-teal)"
      : inventoryColor === "gradientPurple"
      ? "var(--color-gradient-purple)"
      : inventoryColor === "gradientGrey"
      ? "var(--color-gradient-grey)"
      : "initial"};
  background-clip: ${({ inventoryColor }) =>
    inventoryColor?.includes("gradient") ? "text" : "initial"};
  -webkit-background-clip: ${({ inventoryColor }) =>
    inventoryColor?.includes("gradient") ? "text" : "initial"};
  text-fill-color: ${({ inventoryColor }) =>
    inventoryColor?.includes("gradient") ? "transparent" : "initial"};
`

const AffirmContainer = styled.div`
  min-width: 400px;
  & > div {
    background-color: #f7f8f9;
    border: none;
    padding: 0.5rem 1rem;
  }
  @media ${devices.mobile} {
    min-width: 400px;
  }
`

type CurrentInventoryData =
  | { GetInventory: { warehouseCode: string; stock: number } }
  | undefined

export const ProductPriceDisplay = ({
  price,
  discount,
  isBilledMonthly,
  isBilledYearly,
  small = false,
  currency,
  isDeposit,
  stripePrice,
  depositHeading,
  priceHeading,
  productDisplayEnum,
  inventoryBreakpoints,
  priceObj,
  hidePrice,
  enableAffirmMessaging,
  isMobile,
  country,
  enableAffirmCanada,
  enableAffirmUS,
  enableFrontRow,
}: {
  price: number
  discount: number
  isBilledMonthly: boolean
  isBilledYearly: boolean
  small?: boolean
  currency: Currency
  isDeposit?: boolean
  stripePrice?: number
  depositHeading?: string
  priceHeading?: string
  productDisplayEnum?: ProductDisplay
  inventoryBreakpoints?: InventoryBreakpoints
  priceObj: ProductPriceObj
  hidePrice?: boolean
  enableAffirmMessaging: boolean
  isMobile?: boolean
  country: string
  enableAffirmCanada: boolean
  enableAffirmUS: boolean
  enableFrontRow: boolean
}) => {
  let showDeposit: boolean = false

  if (!productDisplayEnum) {
    if (isDeposit) {
      showDeposit = true
    }
  } else {
    if (isDeposit && ["all"].includes(productDisplayEnum)) {
      showDeposit = true
    }
  }

  const showFullPrice =
    productDisplayEnum &&
    ["priceOnly"].includes(productDisplayEnum) &&
    discount > 0

  return (
    <CardWrapper>
      <Card small={small}>
        {showDeposit && (
          <div>
            <Title small={small}>{depositHeading}</Title>
            <Row>
              <StyledPrice small={small} color="var(--color-hot-pink)">
                {formatCurrencyString({
                  value: stripePrice!,
                  currency: currency.toUpperCase(),
                })}
                <sup>{currency.toUpperCase()}</sup>
                {isBilledMonthly && <sup>Billed Monthly</sup>}
                {isBilledYearly && <sup>Billed Yearly</sup>}
              </StyledPrice>
            </Row>
          </div>
        )}
        {showDeposit && <Dividor small={small} />}
        <div>
          <Title small={small}>{priceHeading}</Title>
          <Row>
            <StyledPrice small={small} id="product-price">
              <div>
                {formatCurrencyString({
                  value: hidePrice
                    ? 0
                    : showFullPrice
                    ? price
                    : price - discount,
                  currency: currency.toUpperCase(),
                })}

                <sup>{currency.toUpperCase()}</sup>
              </div>
              {isBilledMonthly && !hidePrice && (
                <BillingPeriod>Billed Monthly</BillingPeriod>
              )}
              {isBilledYearly && !hidePrice && (
                <BillingPeriod>Billed Yearly</BillingPeriod>
              )}
            </StyledPrice>
            {(!productDisplayEnum ||
              ["all", "priceAndDiscount"].includes(productDisplayEnum)) &&
              discount !== 0 && (
                <PriceStrike>
                  <span className="strike">
                    {formatCurrencyString({
                      value: price,
                      currency: currency.toUpperCase(),
                    })}
                  </span>
                  <Tag
                    color="var(--color-hot-pink)"
                    text={`Save 
              ${formatCurrencyString({
                value: discount,
                currency: currency.toUpperCase(),
              })}`}
                  />
                </PriceStrike>
              )}
          </Row>
        </div>
      </Card>

      {enableAffirmMessaging &&
        !isMobile &&
        !isBilledMonthly &&
        !isBilledYearly && (
          <AffirmContainer>
            <AffirmMessaging
              total={price}
              currency={currency}
              country={country}
              enableAffirmCanada={enableAffirmCanada}
              enableAffirmUS={enableAffirmUS}
            />
          </AffirmContainer>
        )}
      {enableFrontRow && <FrontrowWidget />}

      <Inventory
        inventoryBreakpoints={inventoryBreakpoints}
        priceObj={priceObj}
      />
    </CardWrapper>
  )
}

export const Inventory = ({
  inventoryBreakpoints,
  priceObj,
}: {
  inventoryBreakpoints: InventoryBreakpoints | undefined
  priceObj: ProductPriceObj
}) => {
  const [inventoryMessage, setInventoryMessage] = useState("")
  const [inventoryColor, setInventoryColor] = useState("")
  const [fixedInventoryData, setFixedInventoryData] = useState<
    CurrentInventoryData
  >()
  const stripeId = getStripeId(priceObj.stripeId, priceObj.stripeTestId)
  const stripeProduct = getCartProduct(stripeId)

  const [
    GetInventory,
    {
      loading: getInventoryLoading,
      error: getInventoryError,
      data: getInventoryData,
      errMsg: getInventoryMsg,
    },
  ] = useLazyApi("billing", "GetInventory")

  useEffect(() => {
    if (
      stripeProduct &&
      stripeProduct.metadata &&
      stripeProduct.metadata.bladeSKU &&
      inventoryBreakpoints
    ) {
      GetInventory({
        variables: {
          input: {
            id: stripeProduct.metadata.bladeSKU,
            country: getLs("country_for_inventory") || "United States",
          },
        },
      })
    } else if (
      stripeProduct &&
      stripeProduct.metadata &&
      inventoryBreakpoints
    ) {
      setFixedInventoryData({ GetInventory: { stock: 0, warehouseCode: "" } })
    }
  }, [])

  const currentInventoryData = fixedInventoryData
    ? fixedInventoryData
    : getInventoryData

  useEffect(() => {
    const currentInventory = currentInventoryData?.GetInventory
    if (
      inventoryBreakpoints &&
      inventoryBreakpoints.length &&
      currentInventory
    ) {
      const breakPoint = inventoryBreakpoints.find(
        breakpoint =>
          currentInventory.stock >= breakpoint.breakpointStart &&
          currentInventory.stock <= breakpoint.breakpointEnd
      )

      if (breakPoint) {
        setInventoryColor(breakPoint.textColor)
        setInventoryMessage(breakPoint.breakpointText)
      }
    }
  }, [currentInventoryData])

  return inventoryMessage ? (
    <InventoryMessage inventoryColor={inventoryColor}>
      {inventoryMessage}
    </InventoryMessage>
  ) : null
}

const ProductPriceDisplayMobile = ({
  price,
  discount,
  currency,
  hidePrice,
  enableAffirmMessaging,
}: {
  price: number
  discount: number
  currency: Currency
  hidePrice?: boolean
  enableAffirmMessaging: boolean
}) => {
  return (
    <>
      <StyledPriceMobile>
        {formatCurrencyString({
          value: hidePrice ? 0 : price - discount,
          currency: currency.toUpperCase(),
        })}

        <sup>{currency.toUpperCase()}</sup>
      </StyledPriceMobile>
    </>
  )
}

const ProductPrice = ({
  priceObj,
  small = false,
  productDisplayEnum,
  isMobile,
  hideFrontRow,
}: {
  priceObj: ProductPriceObj
  small?: boolean
  productDisplayEnum?: ProductDisplay
  isMobile?: boolean
  hideFrontRow?: boolean
}) => {
  if (!priceObj) {
    return <></>
  }

  const strapiData: StrapiData = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          enableAffirmMessaging
          enableAffirmCanada
          enableAffirmUS
          enableFrontRow
        }
      }
    }
  `)
  const {
    enableAffirmMessaging,
    enableAffirmCanada,
    enableAffirmUS,
    enableFrontRow,
  } = strapiData?.strapi?.siteConfig

  const checkoutState = useCheckoutState()
  const { currency, cartItems, couponDiscountDays, country } = checkoutState
  const [isDeposit, setIsDeposit] = useState<boolean>(false)

  setLs("pt_currency", { value: currency })

  let price = priceObj.USD_List_Price
  let discount = priceObj.USD_Discount
  const isBilledMonthly = priceObj.isBilledMonthly
  const isBilledYearly = priceObj.isBilledYearly
  if (currency === "cad") {
    price = priceObj.CAD_List_Price
    discount = priceObj.CAD_Discount
  }

  // If we have a Stripe price, use it rather than the price from Strapi
  let stripePrice
  const stripeId = getStripeId(priceObj.stripeId, priceObj.stripeTestId)
  const stripeProduct = getCartProduct(stripeId)

  if (stripeProduct) {
    if (currency === "cad" && stripeProduct.prices.cad) {
      price = stripeProduct.prices.cad.unit_amount + discount
    } else if (currency === "usd" && stripeProduct.prices.usd) {
      price = stripeProduct.prices.usd.unit_amount + discount
    }
  }

  const netPrice = price - discount

  setLs("pt_price", { value: price })
  setLs("pt_discount", { value: discount })

  if (stripeProduct) {
    const { prices } = stripeProduct
    stripePrice = prices[currency]?.unit_amount
    if (stripePrice && stripePrice < netPrice) {
      setIsDeposit(true)
    }
  }

  const hidePrice =
    (isBilledYearly || isBilledMonthly) &&
    (isHeadsetSubscriptionPromoApplicable(cartItems) || couponDiscountDays > 0)

  return (
    <>
      <Wrapper>
        {!isMobile && (
          <ProductPriceDisplay
            price={price}
            discount={discount}
            currency={currency}
            isBilledMonthly={isBilledMonthly}
            isBilledYearly={isBilledYearly}
            small={small}
            isDeposit={isDeposit}
            stripePrice={stripePrice}
            depositHeading={priceObj.depositHeading}
            priceHeading={priceObj.priceHeading}
            productDisplayEnum={productDisplayEnum}
            inventoryBreakpoints={priceObj.inventoryBreakpoints}
            priceObj={priceObj}
            hidePrice={hidePrice}
            enableAffirmMessaging={enableAffirmMessaging}
            isMobile={isMobile}
            country={country}
            enableAffirmCanada={enableAffirmCanada}
            enableAffirmUS={enableAffirmUS}
            enableFrontRow={enableFrontRow && !hideFrontRow}
          />
        )}
        {isMobile && (
          <PriceDisplayMobileContainer hasMargin={true}>
            <ProductPriceDisplayMobile
              price={price}
              discount={discount}
              currency={currency}
              hidePrice={hidePrice}
              enableAffirmMessaging={
                enableAffirmMessaging && !isBilledMonthly && !isBilledYearly
              }
            />
          </PriceDisplayMobileContainer>
        )}
      </Wrapper>
      {enableAffirmMessaging &&
        isMobile &&
        !isBilledMonthly &&
        !isBilledYearly && (
          <div style={{ paddingTop: "2rem" }}>
            <AffirmContainer>
              <AffirmMessaging
                total={price}
                currency={currency}
                country={country}
                enableAffirmCanada={enableAffirmCanada}
                enableAffirmUS={enableAffirmUS}
              />
            </AffirmContainer>
          </div>
        )}
    </>
  )
}

export default ProductPrice
