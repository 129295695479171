import React from "react"
import styled from "styled-components"
import { Hidden, Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ButtonProps,
  Image,
  SectionCore,
  FeaturedVideo,
  ProductPriceObj,
  ProductDisplay,
} from "../../ts/interfaces"
import MarkdownContent from "../ui/MarkdownContent"
import { SectionTitle } from "../ui/Title"
import { SectionDividor } from "../ui/line"
import PaddedContainer from "../ui/PaddedContainer"
import { devices } from "../config/breakpoints"
import { Button, Buttons } from "../ui/Button"
import FeatureImageVideo from "../ui/FeatureImageVideo"
import { InlineMediaProps } from "../ui/InlineMedia"
import ProductPrice from "../products/ProductPrice"

export interface ContentSection {
  id?: string
  content: string
  align: "center" | "right" | "left"
  core: SectionCore
  featuredImage?: Image
  wideImage?: boolean
  maxWidth?: number
  largeFormat?: boolean
  buttons: { button: ButtonProps }[]
  featuredVideo?: FeaturedVideo
  inlineMedia?: InlineMediaProps[]
  productPriceDisplay?: ProductPriceObj
  productDisplayEnum?: ProductDisplay
  alignButtons?: "center" | "right" | "left"
  padding?: string
}

const Section = styled.div`
  position: relative;
`

const OutterContainer = styled(props => <div {...props} />)`
  position: relative;
  text-align: left;
  z-index: 200;
  margin: 0;
  padding: 0;

  ${({ maxWidth }) =>
    maxWidth
      ? `
      display:flex;
      text-align: center;
  align-items: center;
  justify-content: center;
  `
      : ""}
`

const ContainerWidth = styled(props => <div {...props} />)`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
`
const ContentContainer = styled(props => <div {...props} />)`
  @media ${devices.mobile} {
    margin: 0;
  }

  ${({ largeFormat }) =>
    largeFormat
      ? `& p {
    font-size: 1.1875rem;
    line-height: 2rem;
    margin: 1rem 0;
    @media ${devices.mobile} {
      font-size: 1.1rem;
      line-height: 1.6rem;
    }
  }`
      : ""}

  .inner {
    ${({ align }) =>
      align === "center"
        ? `
    text-align: center;
    margin: 0 2rem;
  `
        : "text-align: left;"}
  }
  ${({ hasbg }) => (hasbg === "true" ? `color: var(--color-white);` : "")}

  & ul {
    list-style-type: circle;
  }
`

const CoverImage = styled(props => <GatsbyImage {...props} />)`
  object-fit: cover;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  object-position: 50% 50%;
  position: absolute !important;
  overflow: hidden;
`

const SimpleContent = (section: ContentSection) => {
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    animate,
    titleAlign,
    underlineTitle,
    smallSubTitle,
  } = section.core
  const [desc, moreDesc] = (section.content || "").split("...")

  return (
    <Section>
      <PaddedContainer padding={section.padding}>
        <OutterContainer maxWidth={section.maxWidth}>
          <ContainerWidth maxWidth={section.maxWidth}>
            <SectionDividor isTop={true} core={section.core} />
            <Grid
              container
              spacing={6}
              alignContent="center"
              alignItems="center"
            >
              <Hidden smDown>
                {!section.largeFormat &&
                  section.featuredImage &&
                  section.align !== "left" && (
                    <Grid
                      data-aos={animate && "fade-up"}
                      item
                      xs={12}
                      md={section.wideImage ? 7 : 5}
                      style={{ textAlign: "center" }}
                    >
                      <div style={{ position: "relative", width: "100%" }}>
                        <FeatureImageVideo
                          featuredImage={section.featuredImage}
                          featuredVideo={section.featuredVideo}
                        />
                      </div>
                    </Grid>
                  )}
              </Hidden>
              <Grid
                data-aos={animate && "fade-up"}
                item
                xs={12}
                md={
                  section.largeFormat
                    ? 12
                    : section.featuredImage && (section.wideImage ? 5 : 7)
                }
              >
                <ContentContainer
                  maxWidth={section.maxWidth}
                  align={section.align}
                  largeFormat={section.largeFormat}
                  hasimg={
                    section.featuredImage !== null &&
                    section.featuredImage !== undefined
                      ? "true"
                      : "false"
                  }
                >
                  <div className="inner">
                    <SectionTitle
                      title={title}
                      titleColor={titleColor}
                      subtitle={subtitle}
                      subtitleColor={subtitleColor}
                      animate={animate}
                      titleAlign={titleAlign || "left"}
                      underlineTitle={underlineTitle}
                      smallSubTitle={smallSubTitle}
                    />
                    {section.largeFormat && section.featuredImage && (
                      <div
                        style={{
                          position: "relative",
                          float: "right",
                          width: "40%",
                          margin: "0 0 2rem 2rem ",
                        }}
                      >
                        <FeatureImageVideo
                          featuredImage={section.featuredImage}
                          featuredVideo={section.featuredVideo}
                        />
                      </div>
                    )}
                    {desc && (
                      <MarkdownContent
                        animate={animate}
                        content={desc}
                        inlineMedia={section.inlineMedia}
                      />
                    )}
                    {!section.largeFormat && section.featuredImage && (
                      <Hidden mdUp>
                        <FeatureImageVideo
                          featuredImage={section.featuredImage}
                          featuredVideo={section.featuredVideo}
                        />
                      </Hidden>
                    )}
                    {moreDesc && (
                      <MarkdownContent
                        animate={animate}
                        content={moreDesc}
                        inlineMedia={section.inlineMedia}
                      />
                    )}
                    {section.productPriceDisplay && (
                      <ProductPrice
                        priceObj={section.productPriceDisplay}
                        productDisplayEnum={section.productDisplayEnum}
                        small={true}
                      />
                    )}
                    {section.buttons && section.buttons.length > 0 && (
                      <Buttons align={section.alignButtons}>
                        {section.buttons.map(b => {
                          return !b.button ? null : (
                            <Button
                              key={b.button.id}
                              {...b.button}
                              sectionId={`${b.button.title}_btn`
                                .toLowerCase()
                                .replace(" ", "")}
                            />
                          )
                        })}
                      </Buttons>
                    )}
                  </div>
                </ContentContainer>
              </Grid>
              <Hidden smDown>
                {!section.largeFormat &&
                  section.featuredImage &&
                  section.align === "left" && (
                    <Grid
                      data-aos={animate && "fade-up"}
                      item
                      xs={12}
                      md={section.wideImage ? 7 : 5}
                      style={{ textAlign: "center" }}
                    >
                      <FeatureImageVideo
                        featuredImage={section.featuredImage}
                        featuredVideo={section.featuredVideo}
                      />
                    </Grid>
                  )}
              </Hidden>
            </Grid>

            <SectionDividor isTop={false} core={section.core} />
          </ContainerWidth>
        </OutterContainer>
      </PaddedContainer>
    </Section>
  )
}
export default SimpleContent
